import React from 'react'
import { colors } from '../variables'
import { css } from '@emotion/react'

export default () => (
  <div
    css={css`
      background-color: black;
      border-radius: 3px;
      box-shadow: 0 5px 10px ${colors.shadow};
      height: 0;
      overflow: hidden;
      padding-bottom: 56.333%;
      position: relative;
    `}
  >
    <iframe
      css={css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      `}
      title="Humpback whale heat run with commentary from Scott Portelli"
      src="https://www.youtube.com/embed/zC6fKHaRzPI?start=2"
      frameBorder="0"
      allowFullScreen="1"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </div>
)
