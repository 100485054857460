import React from 'react'
import { colors } from '../variables'
import { css } from '@emotion/react'

export default ({ children, color = 'default', target = '_blank', to }) => (
  <a
    css={css`
      color: ${color === 'white' ? 'white' : colors.blue75};
      transition: color 0.2s ease-out;
      will-change: color;
      font-weight: ${color === 'white' ? 'bold' : 'normal'};
      text-decoration: none;

      &:visited {
        color: ${color === 'white' ? 'white' : colors.blue100};
      }

      &:active,
      &:focus,
      &:hover {
        color: ${color === 'default' ? colors.blue50 : 'inherit'};
        text-decoration: ${color === 'white' ? 'underline' : 'none'};
      }
    `}
    href={to}
    rel={target === '_blank' ? 'nofollow noopener noreferrer' : 'self'}
    target={target}
  >
    {children}
  </a>
)
