import React from 'react'
import smoothscroll from 'smoothscroll-polyfill'

export default class Anchor extends React.Component {
  componentDidMount() {
    smoothscroll.polyfill()
  }

  scroll() {
    const target = document.querySelector(this.props.target)

    if (target) {
      window.scrollTo({
        behavior: 'smooth',
        top: Math.round(
          target.getBoundingClientRect().top + window.scrollY - 140
        ),
      })
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    return (
      <div css={this.props.className} onClick={() => this.scroll()}>
        {this.props.children}
      </div>
    )
  }
}
