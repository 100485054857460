import { breakpoints, colors } from '../variables'

import Logo from '../images/voja.svg'
import React from 'react'
import background from '../images/footer-background.jpg'
import { css } from '@emotion/react'

export default () => (
  <div
    css={css`
      align-items: flex-end;
      background: ${colors.blue100} url(${background});
      background-size: cover;
      background-position: 25% 75%;
      display: flex;
      height: 50vw;
      min-height: 360px;
      margin: -25vw 0 0;
      max-height: 600px;
      padding: 120px 20px 0 20px;
      position: relative;
      width: 100%;
      z-index: 0;

      @media (min-width: ${breakpoints.tablet}px) {
        padding: 120px 40px 0 40px;
      }

      @media (min-width: ${breakpoints.laptop}px) {
        margin: -20vw 0 0;
      }
    `}
  >
    <div
      css={css`
        align-items: center;
        border-top: 2px solid rgba(255, 255, 255, 0.1);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1200px;
        padding: 1.5rem 0 2rem;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        width: 100%;

        @media (min-width: ${breakpoints.tablet}px) {
          flex-direction: row;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        &copy; copyright {new Date().getFullYear()} -
        <Logo
          className={css`
            margin: 5px 5px 0 10px;
          `}
        />
        travel
      </div>

      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: column;

          @media (min-width: ${breakpoints.small}px) {
            flex-direction: row;
          }
        `}
      >
        <Link href="https://voja-cloud.ams3.digitaloceanspaces.com/public/tonga/algemene-voorwaarden.pdf">
          Algemene voorwaarden
        </Link>

        <Link href="https://voja-cloud.ams3.digitaloceanspaces.com/public/tonga/privacy-statement.pdf">
          Privacy statement
        </Link>
      </div>
    </div>
  </div>
)

const Link = ({ children, href }) => (
  <a
    css={css`
      color: white;
      font-weight: 700;
      text-decoration: none;
      transition: color 0.2s ease-out;
      will-change: color;

      &:active,
      &:focus,
      &:hover {
        text-decoration: underline;
      }

      @media (min-width: ${breakpoints.small}px) {
        margin-left: 20px;
      }
    `}
    href={href}
    rel="nofollow noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
)
