import { StaticQuery, graphql } from 'gatsby'

import Button from './button'
import Img from 'gatsby-image'
import Line from './line'
import Link from './link'
import PhoneNumber from './phone-number'
import React from 'react'
import Subtitle from './subtitle'
import { breakpoints } from '../variables'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        joshua: file(relativePath: { eq: "joshua.jpg" }) {
          childImageSharp {
            fixed(width: 180, height: 180) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        sgr: file(relativePath: { eq: "sgr.png" }) {
          childImageSharp {
            fixed(width: 45, height: 40) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        calamiteitenfonds: file(relativePath: { eq: "calamiteitenfonds.png" }) {
          childImageSharp {
            fixed(width: 43, height: 40) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        fairtravel: file(relativePath: { eq: "fairtravel.png" }) {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <div
        css={{
          [`@media(min-width: ${breakpoints.laptop}px)`]: {
            marginTop: 'calc(-3.5vw - 40px)',
          },
        }}
      >
        <Img
          fixed={data.joshua.childImageSharp.fixed}
          style={{
            borderRadius: '100%',
            marginBottom: 20,
          }}
        />
        <h3 css={{ marginBottom: 5 }}>Over Voja travel</h3>
        <Subtitle small>Changing Lives. For a better tomorrow.</Subtitle>
        <Line small />
        <p>
          Voja travel is opgericht door Joshua van Eijndhoven in 2017 en er
          werken nu 11 mensen. Voja travel biedt je nieuwe manieren om jezelf en
          de wereld te ontdekken. Een Voja travel reis kan inspireren,
          perspectieven veranderen en je verrijken. Joshua heeft voor Voja
          travel zes jaar rond de wereld gezeild en schreef het boek&nbsp;
          <Link to="https://www.bol.com/nl/p/op-reis-met-de-hope/9200000079270436/?suggestionType=typedsearch&amp;bltgh=jEOdviuNimCY1abGZgFBDA.1_2.3.ProductTitle">
            Op Reis met de Hope
          </Link>
          . Tonga was de mooiste plek waar hij ooit is geweest.
        </p>
        <Button
          external
          link="https://www.bol.com/nl/p/op-reis-met-de-hope/9200000079270436/?suggestionType=typedsearch&amp;bltgh=jEOdviuNimCY1abGZgFBDA.1_2.3.ProductTitle"
        >
          Bestel het boek
        </Button>
        <PhoneNumber
          className={{
            marginTop: 30,
            marginBottom: 30,
            [`@media(min-width: ${breakpoints.tablet}px)`]: {
              marginTop: 60,
              marginBottom: 60,
            },
          }}
          iconColor="white"
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: 160,
          }}
        >
          <Img fixed={data.sgr.childImageSharp.fixed} />
          <Img fixed={data.calamiteitenfonds.childImageSharp.fixed} />
          <Img fixed={data.fairtravel.childImageSharp.fixed} />
        </div>
      </div>
    )}
  />
)
