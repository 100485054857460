import Expert from './expert'
import Form from './form'
import React from 'react'
import Wrapper from './wrapper'
import { breakpoints } from '../variables'

export default () => (
  <div id="contact">
    <Wrapper last={true} blue={true}>
      <div
        css={{
          maxWidth: 800,
          [`@media(min-width: ${breakpoints.laptop}px)`]: {
            display: 'flex',
            marginBottom: 'calc(-7.5vw - 100px)',
            maxWidth: 'none',
          },
        }}
      >
        <div
          css={{
            flexBasis: 600,
            marginBottom: 60,
            [`@media(min-width: ${breakpoints.laptop}px)`]: {
              marginBottom: 0,
              marginRight: 60,
            },
            [`@media(min-width: ${breakpoints.full}px)`]: {
              flexBasis: 720,
              marginRight: 120,
            },
          }}
        >
          <Form />
        </div>

        <div
          css={{
            flexBasis: 360,
          }}
        >
          <Expert />
        </div>
      </div>
    </Wrapper>
  </div>
)
