import { StaticQuery, graphql } from 'gatsby'
import { breakpoints, colors } from '../variables'

import Anchor from './anchor'
import Button from './button'
import Image from './image'
import Line from './line'
import React from 'react'
import Subtitle from './subtitle'
import Video from './video'
import Wrapper from './wrapper'
import { css } from '@emotion/react'
import globe from '../images/globe.jpg'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "experience-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 480) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2: file(relativePath: { eq: "experience-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 660) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        scott: file(relativePath: { eq: "scott.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper
        blue={true}
        className={`
          margin-top: 240px;

          @media(min-width: ${breakpoints.laptop}px) {
            margin-top: 0;
            padding-bottom: calc(20vw - 60px);
          }

          @media(min-width: ${breakpoints.full}px) {
            margin-top: 0;
            padding-bottom: calc(20vw - 120px);
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.laptop}px) {
              flex-direction: row;
            }
          `}
        >
          <div
            css={css`
              flex-shrink: 0;
              background: white url(${globe}) 50% / cover;
              width: 360px;
              height: 360px;
              border-radius: 100%;
              margin: -240px 0 40px -80px;
              box-shadow: 0 5px 25px ${colors.shadow};

              @media (min-width: ${breakpoints.tablet}px) {
                margin: -240px 0 60px -120px;
              }

              @media (min-width: ${breakpoints.laptop}px) {
                width: 600px;
                height: 600px;
                margin: -300px 60px 60px -300px;
              }

              @media (min-width: 1600px) {
                margin: -300px 120px 60px -360px;
              }
            `}
          />

          <div
            css={css`
              @media (min-width: ${breakpoints.laptop}px) {
                margin-top: 60px;
              }

              @media (min-width: ${breakpoints.full}px) {
                margin-top: 0px;
              }
            `}
          >
            <h2
              css={css`
                margin-bottom: 0.25em;
              `}
            >
              Zwemmen met walvissen
            </h2>
            <Subtitle>Het grootste avontuur van je leven</Subtitle>
            <Line />

            <p
              css={css`
                margin-bottom: 3rem;

                @media (min-width: ${breakpoints.tablet}px) {
                  margin-bottom: 4rem;
                }
              `}
            >
              Joshua vertelt: “Toen wij naar Tonga zeilden, wilden wij 3 weken
              blijven. Ik was verbaasd toen ik hoorde dat je hier met walvissen
              kon zwemmen. Kan dat? Is dat niet gevaarlijk?” Maar Tonga is de
              enige plek in de wereld waar dit mag. Hier vaart een handjevol
              boten met professionele walvisgidsen. “In het water, oog in oog
              met het grootste dier dat ooit op aarde leefde. Voor mij was het
              de mooiste ervaring van zes jaar wereldzeilen. Die 3 weken werden
              bijna 3 maanden. Het was waanzinnig.”
            </p>

            <div
              css={css`
                margin-left: 60px;

                @media (min-width: ${breakpoints.small}px) {
                  margin-left: 120px;
                }

                @media (min-width: ${breakpoints.laptop}px) {
                  margin-left: 0;
                }
              `}
            >
              <Video />
            </div>
          </div>
        </div>

        <Image
          className={`
            margin: -60px 0 0;
            max-width: 480px;
            width: 50%;
            z-index: -1;

            @media(min-width: ${breakpoints.small}px) {
              margin: -120px 0 0;
            }

            @media(min-width: ${breakpoints.laptop}px) {
              margin: -300px 0 0;
            }
          `}
          fluid={data.image1.childImageSharp.fluid}
        />

        <div
          css={css`
            display: flex;
            flex-direction: column;

            @media (min-width: ${breakpoints.laptop}px) {
              flex-direction: row;
            }
          `}
        >
          <Image
            className={`
              margin: -60px 0 40px 0;
              max-width: 540px;
              width: 66%;
              z-index: -1;
              align-self: flex-end;
              flex-shrink: 0;

              @media(min-width: ${breakpoints.small}px) {
                margin: -120px 0 40px 0;
              }

              @media(min-width: ${breakpoints.laptop}px) {
                align-self: flex-start;
                margin: -60px 60px 0 60px;
              }

              @media(min-width: ${breakpoints.full}px) {
                max-width: 660px;
                margin: -60px 120px 0 60px;
              }
            `}
            fluid={data.image2.childImageSharp.fluid}
          />

          <div
            css={css`
              margin-top: 0px;

              @media (min-width: ${breakpoints.small}px) {
                margin-top: -60px;
              }

              @media (min-width: ${breakpoints.laptop}px) {
                margin-top: -240px;
              }
            `}
          >
            <h3
              css={css`
                margin-bottom: 0.25em;
              `}
            >
              Ontmoet walvissen
            </h3>
            <Subtitle small="true">Oog in oog met een dier van 40 ton</Subtitle>
            <Line small="true" />

            <p>
              De walvissen zwemmen elk jaar 6.000 kilometer op en neer van
              Antarctica naar Tonga om te paren en te kalven. Je vaart hier
              tussen de eilanden met de gids en crew. Als een dier gevonden is,
              gaat de gids eerst en zwem je er achteraan. Walvissen zijn heel
              zachtaardig. Ze hebben zelfs mensen beschermd tegen gevaar. In
              Tonga merk je dat de walvissen nieuwsgierig zijn, zekere de
              kalfjes, maar ze houden voldoende afstand.
            </p>

            <Anchor target="#contact">
              <Button arrow="down">Meer weten</Button>
            </Anchor>
          </div>
        </div>
      </Wrapper>
    )}
  />
)
