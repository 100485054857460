import { breakpoints, colors } from '../variables'

import Anchor from './anchor'
import Button from './button'
import Logo from './logo'
import MenuIcon from './menu-icon'
import PhoneNumber from './phone-number'
import React from 'react'
import Wrapper from './wrapper'
import { css } from '@emotion/react'

const breakpointMin = '@media(min-width: 1024px)'
const breakpointMax = '@media(max-width: 1023px)'

export default class Header extends React.Component {
  state = {
    fixed: false,
    menuOpen: false,
  }

  tick() {
    window.requestAnimationFrame(() => {
      if (this.unmounting) {
        return
      }

      this.setState({ fixed: window.pageYOffset >= 20 })
      this.tick()
    })
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }

    this.tick()
  }

  componentWillUnmount() {
    this.unmounting = true
  }

  setMenuState(menuOpen) {
    this.setState({ menuOpen })
    this.props.onMenuChange(menuOpen)
  }

  render() {
    return (
      <Wrapper
        className={css`
          background-color: ${this.state.fixed ? 'white' : 'rgba(0,0,0,.25)'};
          box-shadow: ${this.state.fixed
            ? `0 0 20px ${colors.shadow}`
            : 'none'};
          left: 0;
          position: ${this.state.fixed ? 'fixed' : 'absolute'};
          right: 0;
          transition: background-color 0.2s ease-out;
          will-change: background-color;
          z-index: 3;
        `}
      >
        <div
          css={css`
            align-items: center;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1);
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            transition: color 0.2s ease-out;
            will-change: color;
            height: 80px;

            @media (min-width: ${breakpoints.tablet}px) {
              height: ${this.state.fixed ? '80px' : '120px'};
            }

            ${breakpointMax} {
              color: ${colors.grey80};
            }

            ${breakpointMin} {
              color: ${this.state.fixed ? colors.grey80 : 'white'};
            }
          `}
        >
          <div
            css={{
              maxWidth: 90,
              [`@media(min-width: ${breakpoints.tablet}px)`]: {
                maxWidth: 'none',
              },
              [breakpointMin]: {
                marginRight: 25,
              },
            }}
          >
            <Logo fixed={this.state.fixed} />
          </div>
          <div
            css={{
              [breakpointMax]: {
                alignItems: 'center',
                display: 'flex',
              },
              [breakpointMin]: {
                display: 'none',
              },
            }}
          >
            <div
              css={{
                display: 'none',
                [`@media(min-width: 550px)`]: {
                  display: 'block',
                  marginRight: 40,
                },
              }}
            >
              {!this.state.fixed && (
                <PhoneNumber white={true} iconColor="white" small />
              )}
              {this.state.fixed && <PhoneNumber iconColor="lightBlue" small />}
            </div>
            <div css={{ zIndex: 1 }}>
              <MenuIcon
                onChange={(menuOpen) => this.setMenuState(menuOpen)}
                open={this.state.menuOpen}
                fixed={this.state.fixed}
              />
            </div>
          </div>
          <div
            css={{
              [breakpointMax]: {
                alignItems: 'center',
                background: colors.blue5,
                display: 'flex',
                left: 0,
                height: '100%',
                justifyContent: 'center',
                opacity: this.state.menuOpen ? 1 : 0,
                overflowY: 'scroll',
                padding: '4rem 0 8rem',
                pointerEvents: this.state.menuOpen ? 'all' : 'none',
                position: 'fixed',
                top: 0,
                transition: 'opacity .3s cubic-bezier(0, 0, 0, 1)',
                width: '100%',
              },
            }}
          >
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                [breakpointMax]: {
                  flexDirection: 'column',
                  margin: 'auto 0',
                },
              }}
            >
              <div
                css={{
                  [breakpointMax]: {
                    marginBottom: '2rem',
                    textAlign: 'center',
                  },
                  [breakpointMin]: {
                    display: 'flex',
                    marginRight: 15,
                  },
                  [`@media(min-width: 1100px)`]: {
                    marginRight: 30,
                  },
                  [`@media(min-width: ${breakpoints.full}px)`]: {
                    marginRight: 40,
                  },
                }}
              >
                <Link to="#de-reis" onClick={() => this.setMenuState(false)}>
                  De reis
                </Link>
                <Link
                  to="#dag-tot-dag"
                  onClick={() => this.setMenuState(false)}
                >
                  Dag tot dag
                </Link>
                <Link
                  to="#verantwoord"
                  onClick={() => this.setMenuState(false)}
                >
                  Verantwoord
                </Link>
                <Link to="#praktisch" onClick={() => this.setMenuState(false)}>
                  Praktisch
                </Link>
                <Link to="#prijzen" onClick={() => this.setMenuState(false)}>
                  Prijzen
                </Link>
              </div>
              <div
                css={{
                  [breakpointMax]: {
                    marginBottom: '2rem',
                  },
                  [breakpointMin]: {
                    marginRight: 10,
                  },
                  [`@media(min-width: ${breakpoints.laptop}px)`]: {
                    display: 'none',
                  },
                }}
              >
                <PhoneNumber small />
              </div>

              <div
                css={{
                  display: 'none',

                  [`@media(min-width: ${breakpoints.laptop}px)`]: {
                    display: 'flex',
                    marginRight: 10,
                  },
                  [`@media(min-width: ${breakpoints.full}px)`]: {
                    marginRight: 20,
                  },
                }}
              >
                {!this.state.fixed && (
                  <PhoneNumber white={true} iconColor="white" small />
                )}
                {this.state.fixed && (
                  <PhoneNumber iconColor="lightBlue" small />
                )}
              </div>

              <Anchor
                target="#contact"
                onClick={() => this.setMenuState(false)}
              >
                <Button
                  arrow={false}
                  className={{
                    cursor: 'pointer',
                    padding: '10px 1.3rem',
                    [`@media(min-width: ${breakpoints.tablet}px)`]: {
                      padding: '10px 1.3rem',
                    },
                  }}
                >
                  Contact
                </Button>
              </Anchor>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

const Link = ({ children, to, onClick }) => (
  <div
    css={{
      cursor: 'pointer',
      [breakpointMax]: {
        fontSize: '1.1rem',
        fontWeight: 700,
        paddingBottom: 10,
        transition: 'transform .2s cubic-bezier(0, 0, 0, 1)',
        '&:last-child': {
          paddingBottom: 0,
        },
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
      [breakpointMin]: {
        fontWeight: 700,
        marginRight: 15,
        position: 'relative',
        '&:last-child': {
          marginRight: 0,
        },
        '&::after': {
          background: 'white',
          bottom: 0,
          content: '""',
          display: 'block',
          height: 2,
          position: 'absolute',
          transformOrigin: 0,
          transform: 'scaleX(0)',
          transition: 'transform .2s cubic-bezier(0, 0, 0, 1)',
          width: '100%',
        },
        '&:hover': {
          '&::after': {
            transform: 'scaleX(.6)',
          },
        },
      },
      [`@media(min-width: 1100px)`]: {
        marginRight: 20,
      },
      [`@media(min-width: ${breakpoints.full}px)`]: {
        marginRight: 35,
      },
    }}
  >
    <Anchor target={to} onClick={onClick}>
      {children}
    </Anchor>
  </div>
)
