import { breakpoints, colors } from '../variables'

import Anchor from './anchor'
import React from 'react'

export default fixed => (
  <Anchor
    className={{
      color: fixed.fixed ? colors.grey80 : 'white',
      cursor: 'pointer',
      textDecoration: 'none',
    }}
    target="#top"
  >
    <div
      css={{
        width: '200px',
        height: '60px',
        marginTop: '30px',

        [`@media(min-width: ${breakpoints.tablet}px)`]: {
          height: '90px',
          marginTop: '50px',
        },
      }}
    >
      <svg
        data-name="Laag 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 4744.67 3784.05"
        css={{
          fill: fixed.fixed ? colors.blue100 : 'white',
          alignItems: 'center',
          float: 'left',
          fontSize: '30px',
          justifyContent: 'space-between',
          transform: fixed.fixed ? 'scale(.8)' : 'none',
          marginTop: fixed.fixed ? -25 : -20,
          width: '50%',
          marginRight: fixed.fixed ? 6 : 12,

          [`@media(min-width: ${breakpoints.tablet}px)`]: {
            marginTop: fixed.fixed ? -20 : -15,
          },
        }}
      >
        <title>voja-travel</title>
        <path
          d="M2050.9,3296.45V3174.2h-33.39v-89.49h33.39v-97.05l112.81-11.35v108.4h66.8v89.49h-66.8v98.31c0,14.49,1.9,30.87,20.17,30.87a73.7,73.7,0,0,0,22.68-4.41l27.73,78.15c-23.31,13.24-46,22.68-90.75,22.68C2091.23,3399.8,2050.9,3367,2050.9,3296.45Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M2509,3078.41v120.37c-9.46-.64-18.91-.64-23.95-.64-43.49,0-63.66,24.57-63.66,60.5V3393.5H2308.64V3084.71h112.79v42.85h.64c22-32.13,52.94-49.15,86.34-49.15Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M2889,3084.71V3393.5H2781.88v-29h-.62c-18.28,23.95-45.37,35.29-79.41,35.29-84.44,0-143.69-65.54-143.69-160.69s59.87-160.7,136.76-160.7c30.25,0,59.24,12.6,80.66,37.18h.64v-30.88Zm-107.76,154.4c0-36.56-24.58-61.77-54.21-61.77-32.13,0-54.82,25.21-54.82,61.77,0,37.81,22.69,62.38,54.82,62.38C2756.68,3301.49,2781.26,3276.92,2781.26,3239.11Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M3286.64,3084.71,3168.8,3393.5H3067.34L2949.5,3084.71h116.59l51.66,175.82h.64l51.05-175.82Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M3627.57,3266.84H3425.28c5,29.61,30.88,44.1,63.65,44.1,27.73,0,49.78-12,71.21-34l58,64.27c-26.47,32.15-75.63,58.61-141.17,58.61-92.64,0-161.95-65.54-161.95-160.69,0-93.27,66.79-160.7,160.06-160.7,88.86,0,155,65.54,155,158.18A246.79,246.79,0,0,1,3627.57,3266.84Zm-202.29-61.14h95.79c-5.05-26.46-22.69-41.59-46.64-41.59C3449.22,3164.11,3430.32,3179.24,3425.28,3205.7Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M3705.69,2929.68h112.8V3393.5h-112.8Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M1644.88,2306.92a385.4,385.4,0,0,0-75-62.54c-5.61-4.52-59.73-35.14-62.1-26.09,0,0,34.27,156.51-204.46,418.19-31.18,34.17-115.52,93.94-129.55,35.93s65-244.14,121-324.79c30.41-45.28,191-273.09,263.94-230,80.43,47.58,88.16,123,93.42,128.37,2.2-2.57,41.95-62.1-2.52-162.94-35.39-77.57-122.9-138.86-201.18-126.79-69.65,11-122.69,58.87-172.5,104.15a1014.31,1014.31,0,0,0-159.35,184.15,983.63,983.63,0,0,0-120.54,234.82c-26.95,80.65-40.75,162.37,9.06,237.19a388.19,388.19,0,0,0,100.27,97.47c48.73,35.15,105.22,66,165.17,61.66,116-9.87,274-200.09,345.22-326.68,20.17-35.85,79.78-150.29,64.69-191.69A147.32,147.32,0,0,0,1644.88,2306.92Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M2265.2,2136.92c-19-40.91-94.3-126.59-184-181.59a26.26,26.26,0,0,0-6.59.67c-118.89,103.89-224,142.09-288,142.28,9.84,72.08,68.84,104,105.72,122.47,49.84,24.92,104,12.43,104,12.43-115.55,257.71-372,661.56-536.75,862.08-141,171.61-239.51,193.7-297.28,197.1C1223.55,3334.87,1370,3396,1403.72,3396c59.28,0,155.21-88.73,258.27-226C1872.5,2889.65,2265.26,2218.48,2265.2,2136.92Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M1207.51,1461.9c-42.44,53.85-87.47,121.6-111.84,159.53-64.26,100.27-243,373.47-282.69,438.16-159.35,262-319.78,542.95-333.14,544.68-4.06-173.39,172.28-595.35,172.28-595.35-36.65-44.21-66.84-82.38-101.34-127.87-23.07-30.4-40.45-36.39-79.35-17-74.5,37-283.45,117.57-377.34,146.48,15,23.06,73.29,104.34,136.16,123,70.14,20.85,107.22,12.64,145.46-3.63-4.38,12-69,209.23-118.84,447.21a30.12,30.12,0,0,0-1.07,14.2c78.59,109.76,183.8,215,301.36,310.9,0,0,205.8-371.61,286.21-508.14C1000.83,2126.73,1347.46,1617,1412.52,1546,1343.21,1497.18,1240,1454.36,1207.51,1461.9Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M3231.28,2577.57c-90.63,6.8-150.64,79.95-151.94,85.35,1.51-6.68-42.9-6.05,81.51-283.76,36.18-80.77,109.54-202,110.83-218.44.16-2-.11-3.81-.1-5.75-21.16-61.58-136.22-171.48-193-196.31-14.15,16.72-30.36,51.62-41.92,69.88-111.91,176.17-232,340.91-424.36,493.36-58,45.92-145.76,108.46-190.61,112.12a3.25,3.25,0,0,0-1.52,0c-23.46-13.68-11.86-73.79,6.46-111.31,65.12-133.92,199.79-269.29,324.1-348.19,104.75-66.49,107.61,34.72,107.61,34.72,18.38,2.44,60-86.76,34.71-152.67a160,160,0,0,0-56.49-68.14c-25.37-17-69.43-10.86-142.5,25.67-71.21,35.61-300.22,200.64-449.41,476.31-51,94.24-39.1,195.34,15.1,276.44,19.89,29.76,78.92,98.33,136.06,90.79,77.19-10.14,173.58-81.51,232.88-127q94.88-71.6,183.71-151c12.08-10.56,90.57-85.38,104.16-98.1,0,0-35.59,96.38-40.54,110.4-11.65,33.85-35.8,101.77-25.23,116.44a187.14,187.14,0,0,0,26.52,33c32.13,27.81,63,55.19,96.82,79.78,28.46,20.49,71.15,53.69,104.15,59.52s75.68-39.89,100.91-61.46a881.94,881.94,0,0,0,84.74-83.24c23.82-27.6,59.56-65.09,83-105.2C3344.59,2607,3278.74,2574,3231.28,2577.57Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M1307.21,1324.57l6.24.66-6.13-.82Z"
          transform="translate(-94.09 -576.68)"
        />
        <path
          d="M4838.76,2415.46c0-388.77-121.08-918.31-545.46-1326.88-346.91-334-805.29-511.9-1370.37-511.9-351.84,0-727,107.6-962.92,246.23-246.29,144.7-400.91,297.52-342.21,419.75,0,0,33.92-37.63,61.84-65.55C2010.42,846.34,2450.2,664.17,2918,664.17s931.35,158.4,1262.56,488.73c363,362,516.93,810.93,512.94,1278.71-4.24,498.07-166,899.51-496.79,1230.28-231,231-530.65,381.7-854.6,446.83l4.8-3.32c-1006.82,185.18-1529-331.1-1578.84-475.26-2.14-6.19-8.4-7.27-13.82-3.58-42.44,28.9-106.14,237.38-90.53,291.59,9.39,32.61,385.87,384.29,1098.46,436.16q64.24,5.07,129.28,5.93,42.91.83,87.23.21l1.51-1.05c496.21-14.89,964-212.49,1321.18-560.55C4686.66,3423.4,4838.76,2976.31,4838.76,2415.46Z"
          transform="translate(-94.09 -576.68)"
        />
      </svg>
      <div
        css={{
          fontFamily: 'Sansita',
          fontSize: '20px',
          fontWeight: '700',
          position: 'relative',
          top: '-2px',
        }}
      >
        Pacific
      </div>
    </div>
  </Anchor>
)
