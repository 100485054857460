import Img from 'gatsby-image'
import React from 'react'
import { colors } from '../variables'

export default ({ className, fluid }) => (
  <Img
    css={[
      `
        border-radius: 3px;
        box-shadow: 0 5px 10px ${colors.shadow};
      `,
      className,
    ]}
    fluid={fluid}
  />
)
