import React from 'react'
import { colors } from '../variables'

export default class MenuIcon extends React.Component {
  state = {
    open: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.props.onChange(this.state.open)
    }

    if (
      prevProps.open !== this.props.open &&
      this.props.open !== this.state.open
    ) {
      this.setState({ open: this.props.open })
    }
  }

  render() {
    return (
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 18,
          width: 28,
        }}
        onClick={() => this.setState({ open: !this.state.open })}
      >
        <Line
          fixed={this.props.fixed}
          className={
            this.state.open
              ? {
                  background: colors.blue75,
                  transform: 'rotate(-45deg) translateY(-2.67px)',
                }
              : {}
          }
        />
        <Line
          fixed={this.props.fixed}
          className={[
            { transformOrigin: '50% 50%' },
            this.state.open
              ? {
                  background: colors.blue75,
                  transform: 'scaleX(0)',
                }
              : {},
          ]}
        />
        <Line
          fixed={this.props.fixed}
          className={
            this.state.open
              ? {
                  background: colors.blue75,
                  transform: 'rotate(45deg) translateY(2.67px)',
                }
              : {}
          }
        />
      </div>
    )
  }
}

const Line = ({ className, fixed }) => (
  <div
    css={[
      {
        background: fixed ? colors.grey80 : 'white',
        borderRadius: 3,
        height: 2,
        transformOrigin: '100% 50%',
        transition:
          'transform .3s cubic-bezier(0, 0, 0, 1), background .3s cubic-bezier(0, 0, 0, 1)',
      },
      className,
    ]}
  />
)
