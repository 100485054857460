import { StaticQuery, graphql } from 'gatsby'
import { breakpoints, colors, fontSizes } from '../variables'

import Img from 'gatsby-image'
import Line from './line'
import Link from './link'
import React from 'react'
import Subtitle from './subtitle'
import Svg from '../images/co2-neutraal.svg'
import Wrapper from './wrapper'
import { css } from '@emotion/react'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        aliceForrest: file(relativePath: { eq: "alice-forrest.png" }) {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        fairtravel: file(relativePath: { eq: "fairtravel.png" }) {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper>
        <div
          css={css`
            align-items: center;
            margin: 0 auto 3.5rem;
            max-width: 720px;
            text-align: center;

            @media (min-width: ${breakpoints.tablet}px) {
              margin: 0 auto 4.5rem;
            }
          `}
          id="verantwoord"
        >
          <h2>Fair Travel</h2>

          <Line
            green={true}
            className={css`
              margin-left: auto;
              margin-right: auto;
            `}
          />

          <Subtitle
            className={css`
              margin-bottom: 0;
            `}
          >
            Alle reizen van Voja travel zijn eerlijk. Fair Travel noemen we dat.
            Wij compenserendeje CO2 uitstoot van je reis automatisch. Verder
            Doneren we €100 aan de Alice Forrest Foundation die strijdt tegen
            plasticvervuiling. Onze Fair Travel foundation heeft daarnaast nog
            veel meer projecten. Hier lees je er meer over.
          </Subtitle>
        </div>

        <div
          css={css`
            align-items: stretch;
            background-image: linear-gradient(
              ${colors.green50},
              ${colors.green100}
            );
            border-radius: 5px;
            color: white;
            display: flex;
            flex-direction: column;
            font-size: ${fontSizes.small};
            margin-bottom: 60px;
            padding: 40px;
            text-align: center;

            @media (min-width: ${breakpoints.medium}px) {
              flex-direction: row;
              margin-bottom: 120px;
            }

            @media (min-width: ${breakpoints.laptop}px) {
              padding: 60px;
            }
          `}
        >
          <Partner>
            <Img
              className={css`
                margin: 0 0 1rem;
              `}
              fixed={data.aliceForrest.childImageSharp.fixed}
            />

            <p>
              Voor iedere reis doneren we €100 aan Alice Forrest, die Joshua
              ontmoette tijdens zijn tocht via de stille Zuidzee. Alice
              bestrijdt plastic vervuiling in de Stille Zuidzee. Lees meer over
              het werk van Alice.{' '}
              <Link to="https://www.aliceforrest.com/" color="white">
                Lees meer over het werk van Alice
              </Link>
            </p>
          </Partner>

          <Partner>
            <Img
              className={css`
                margin: 0 0 1rem;
              `}
              fixed={data.fairtravel.childImageSharp.fixed}
            />

            <p>
              Jouw reisgelden komen zo veel mogelijk terecht bij gewone mensen
              in Tonga. Wij werken met lokale mensen, die een positieve bijdrage
              leveren aan de lokale economie en het land.
            </p>
          </Partner>

          <Partner>
            <Svg
              css={css`
                height: 100px;
                width: 100px;
                margin: 0 0 1rem;
              `}
            />

            <p>
              Wij compenseren automatisch al jouw CO2 uitstoot met donaties aan
              de Fair Travel Foundation. Deze stichting plant bomen die maximaal
              CO2 absorberen.
            </p>
          </Partner>
        </div>
      </Wrapper>
    )}
  />
)

const Partner = ({ children }) => (
  <div
    css={css`
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
      padding: 0 0 2rem 0;
      margin: 0 0 2rem 0;
      flex: 1;

      @media (min-width: ${breakpoints.medium}px) {
        border-bottom: none;
        border-right: 2px solid rgba(255, 255, 255, 0.5);
        margin: 0 19px 0 0;
        padding: 0 19px 0 0;
      }

      @media (min-width: ${breakpoints.laptop}px) {
        margin: 0 39px 0 0;
        padding: 0 39px 0 0;
      }

      &:last-child {
        margin: 0;
        padding: 0;
        border: none;
      }

      p {
        margin: 0;
      }
    `}
  >
    {children}
  </div>
)
