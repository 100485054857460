import { breakpoints, colors, fontSizes, fonts } from '../variables'

import Icon from './icon'
import React from 'react'

export default ({
  className,
  fixed,
  iconColor = 'blue',
  small = false,
  white = false,
}) => (
  <div css={[{ display: 'flex' }, className]}>
    <Icon
      className={{
        marginRight: small ? 10 : 20,
      }}
      name="telephone"
      color={iconColor}
      size={small ? 50 : 60}
    />
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          fontFamily: fonts.heading.join(','),
          fontSize: small ? 20 : fontSizes.h3.mobile,
          lineHeight: 1,
          marginBottom: 8,
          [`@media(min-width: ${breakpoints.tablet}px)`]: {
            fontSize: small ? 20 : fontSizes.h3.desktop,
          },
        }}
      >
        <a
          css={{
            color: white ? 'white' : colors.grey80,
            textDecoration: 'none',
          }}
          href="tel:+31850290536"
        >
          085 0290536
        </a>
      </div>
      <div
        css={{
          color: white ? 'white' : colors.grey60,
          fontSize: small ? 12 : fontSizes.small,
          lineHeight: 1,
        }}
      >
        Ma t/m vrij tussen 09:00 - 18:00
      </div>
    </div>
  </div>
)
