import { breakpoints, colors, fontSizes, fonts } from '../variables'

import React from 'react'

export default ({ children, small = false, className }) => (
  <p
    css={[
      `
      color: ${colors.grey50};
      font-family: ${fonts.body.join(',')};
      font-size: ${small ? fontSizes.h3.mobile : fontSizes.h4.desktop};
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: ${small ? '1rem' : '1.5rem'};
      margin-bottom: 1.5rem;

      @media(min-width: ${breakpoints.tablet}px) {
        font-size: ${small ? fontSizes.h4.desktop : fontSizes.h3.desktop};
      }
    `,
      className,
    ]}
  >
    {children}
  </p>
)
