import 'promise-polyfill/src/polyfill'

import { breakpoints, colors } from '../variables'

import Button from './button'
import Line from './line'
import React from 'react'
import Subtitle from './subtitle'
import Video from './video'
import fetch from 'unfetch'

const Field = ({ name, type, placeholder, value, onChange }) => {
  const css = {
    border: 0,
    borderRadius: 3,
    boxShadow: 'none',
    flexGrow: 1,
    fontSize: 16,
    padding: '9px 15px',
    outline: 'none',
    resize: 'vertical',
    '&:focus': {
      boxShadow: `0 1px 10px 2px ${colors.blue100}`,
    },
  }

  return (
    <div
      css={{
        display: 'flex',
        marginBottom: 20,
        '&:last-child': {
          marginBottom: 0,
        },
      }}
    >
      {type === 'textarea' ? (
        <textarea
          rows="5"
          css={[
            {
              resize: 'vertical',
            },
            css,
          ]}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required
        />
      ) : (
        <input
          css={[
            {
              height: 50,
            },
            css,
          ]}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          required
        />
      )}
    </div>
  )
}

export default class Form extends React.Component {
  state = {
    error: '',
    name: '',
    email: '',
    phone: '',
    message: '',
    success: false,
  }

  handleChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })
  handleSubmit = (event) => {
    event.preventDefault()

    const encode = (data) => {
      return Object.keys(data)
        .filter((key) => key !== 'error' && key !== 'success')
        .map(
          (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
    }

    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then((response) => {
        if (!response.ok) {
          this.fail()
          return
        }

        this.setState({ success: true })
      })
      .catch(() => this.fail())
  }

  fail() {
    this.setState({
      error:
        'Sorry, het versturen van het formulier is niet gelukt. Probeer het later nog eens.',
    })
  }

  render() {
    return (
      <div>
        <h2
          css={{
            marginBottom: 5,
          }}
        >
          Vraag een reisvoorstel aan
        </h2>
        <Subtitle>
          Neem contact op voor een telefonisch of persoonlijk gesprek. We maken
          graag een reisvoorstel voor je. We zijn gevestigd in Utrecht.
        </Subtitle>
        <Line />
        <p
          css={{
            [`@media(min-width: ${breakpoints.tablet}px)`]: {
              marginBottom: '3rem',
            },
          }}
        >
          Vul het onderstaande formulier in om deze unieke experience
          werkelijkheid te maken. We nemen dan zo snel mogelijk contact op om
          een afspraak te maken waarin we de mogelijkheden voor jouw reis
          bespreken bij ons op kantoor in Utrecht.
        </p>

        <div
          css={{
            color: 'white',
            minHeight: 300,
            padding: '30px 30px 40px 0',
            position: 'relative',
            [`@media(min-width: ${breakpoints.tablet}px)`]: {
              padding: '60px 60px 80px 0',
            },
            [`@media(min-width: ${breakpoints.full}px)`]: {
              padding: '60px 120px 80px 0',
            },
          }}
        >
          <div
            css={{
              backgroundImage: `linear-gradient(to bottom, #40BFFF, ${colors.blue100})`,
              borderRadius: 10,
              height: '100%',
              position: 'absolute',
              right: 0,
              top: 0,
              width: '300%',
              zIndex: -1,
            }}
          />
          <h5 css={{ color: 'white' }}>Reisvoorstel aanvragen</h5>

          {this.state.success ? (
            <div>
              <p>
                {this.state.name}, je hebt zojuist de eerste stap gezet naar een
                prachtig avontuur! We zullen je zo snel mogelijk bellen of
                mailen om een afspraak te maken. Tijdens je reis zul je met
                walvissen gaan duiken, samen met Scott Portelli. Van de
                onderstaande video worden wij al enthousiast.
              </p>

              <Video />
            </div>
          ) : (
            <form
              css={{
                display: 'flex',
                flexDirection: 'column',
                margin: 0,
              }}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              method="POST"
              name="contact"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="contact" />

              <div css={{ display: 'none' }}>
                <input name="bot-field" />
              </div>

              <Field
                name="name"
                type="text"
                placeholder="Naam"
                value={this.state.name}
                onChange={this.handleChange}
              />
              <Field
                name="email"
                type="email"
                placeholder="E-mailadres"
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Field
                name="phone"
                type="text"
                placeholder="Telefoonnummer"
                value={this.state.phone}
                onChange={this.handleChange}
              />
              <Field
                name="message"
                type="textarea"
                placeholder="Je bericht"
                value={this.state.message}
                onChange={this.handleChange}
              />
              <Button
                color="green"
                className={{
                  cursor: 'pointer',
                  marginRight: 'auto',
                }}
                type="submit"
              >
                Versturen
              </Button>
              {this.state.error && (
                <div
                  css={{
                    background: '#dc3545',
                    borderRadius: 3,
                    color: 'white',
                    lineHeight: 1.5,
                    marginTop: 20,
                    padding: '15px 20px',
                  }}
                >
                  {this.state.error}
                </div>
              )}
            </form>
          )}
        </div>
      </div>
    )
  }
}
