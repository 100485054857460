import Line from './line'
import React from 'react'
import Usp from './usp'
import Wrapper from './wrapper'
import { breakpoints } from '../variables'
import { css } from '@emotion/react'

export default () => (
  <Wrapper>
    <div
      css={css`
        margin-bottom: 60px;

        @media (min-width: ${breakpoints.tablet}px) {
          margin-bottom: 120px;
        }

        @media (min-width: ${breakpoints.laptop}px) {
          display: flex;
        }
      `}
      id="de-reis"
    >
      <div
        css={css`
          max-width: 360px;

          @media (min-width: ${breakpoints.laptop}px) {
            flex: 0 0 360px;
            margin-right: 60px;
            margin-top: 60px;
          }

          @media (min-width: ${breakpoints.full}px) {
            margin-right: 120px;
          }
        `}
      >
        <h2>
          “Wij hebben voor jou dé ultieme once in a lifetime experience
          gecreëerd.”
        </h2>

        <Line />
      </div>

      <div
        css={css`
          @media (min-width: ${breakpoints.laptop}px) {
            width: calc(100% - ${360 + 60}px);
          }

          @media (min-width: ${breakpoints.full}px) {
            width: calc(100% - ${360 + 120}px);
          }
        `}
      >
        <p>
          <em>
            <strong>
              Wereldzeiler Joshua van Eijndhoven zeilde zes jaar rond de wereld
              en doopte deze plekken “de mooiste op aarde”. Voor Voja travel
              stelde hij deze reis samen. Je ontdekt het Koninkrijk van Tonga;
              een bijzondere en weinig bezochte Polynesische eilandstaat in de
              stille Zuidzee. Je gaat ook eilandhoppen in Fiji, de archipel met
              meer dan duizend eilandjes.
            </strong>
          </em>
        </p>

        <p>
          Magie van de stille zuidzee | Bounty eilanden | | Paradijs | Zwemmen
          met walvissen | Ontmoet manta roggen | Polynesische eilandcultuur |
          Kreeft BBQ op het strand | en heel veel meer….
        </p>

        <h4>Wat wij bieden</h4>

        <div
          css={css`
            margin-bottom: -15px;

            @media (min-width: 540px) {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              > div {
                width: calc(50% - 20px);
              }
            }

            @media (min-width: ${breakpoints.tablet}px) {
              margin-bottom: -20px;
            }
          `}
        >
          <Usp icon="voja-travel" large>
            Voja travel experience: Kleinschalig | Authentiek | Ontmoet locals |
            Geen groepsreis
          </Usp>
          <Usp icon="landing">
            Vlucht via Tokyo incl. weekendverblijf mogelijk
          </Usp>
          <Usp icon="check">
            Inclusief vluchten, accommodaties, activiteiten &amp; transfers
          </Usp>
          <Usp icon="calendar">
            Beschikbaar: juni tot en met oktober. Vanaf 2½, 3 of 4 weken.
          </Usp>
          <Usp icon="cutlery">
            Tonga: Inclusief ontbijt. Fiji: inclusief alle maaltijden
          </Usp>
          <Usp color="green" icon="fairtravel" large>
            TODO ICON We compenseren alle CO2 uitstoot. We doneren €100 per reis
            aan de Alice Forest foundation.
          </Usp>
          <Usp icon="thumbsup">Goed geregeld dankzij onze lokale kennis</Usp>
          <Usp icon="whale">
            Zwemmen met walvissen &amp; mantaroggen mogelijk
          </Usp>
        </div>
      </div>
    </div>
  </Wrapper>
)
