import { breakpoints, fonts } from '../variables'

import Anchor from './anchor'
import Button from './button'
import React from 'react'
import Wave from './wave'
import { css } from '@emotion/react'
import image from '../images/background.jpg'

function calculate(height) {
  return Math.ceil((height / 9) * 16)
}

function shouldDisplay() {
  if (
    typeof window !== 'undefined' &&
    window.navigator.connection &&
    window.navigator.connection.effectiveType &&
    window.navigator.connection.effectiveType !== '4g'
  ) {
    return false
  }

  return true
}

const desktop = 1600
const height = {
  tablet: 650,
  laptop: 750,
  full: 900,
  desktop: 1100,
}

export default React.memo(() => (
  <div
    css={css`
      background: #333 url(${image}) 50% / cover;
      height: 480px;
      margin-bottom: 40px;
      overflow: hidden;
      position: relative;

      @media (min-width: 600px) {
        height: 550px;
      }

      @media (min-width: ${breakpoints.tablet}px) {
        height: ${height.tablet}px;
      }

      @media (min-width: ${breakpoints.laptop}px) {
        height: ${height.laptop}px;
        margin-bottom: 0;
      }

      @media (min-width: ${breakpoints.full}px) {
        height: ${height.full}px;
      }

      @media (min-width: ${desktop}px) {
        margin-bottom: -50px;
        height: ${height.desktop}px;
      }
    `}
    id="top"
  >
    {shouldDisplay() && (
      <div
        css={css`
          display: none;

          @media (min-width: ${breakpoints.tablet}px) {
            display: block;
            height: 100%;
            left: 50%;
            position: relative;
            top: 50%;
            transform: translate(-50%, -50%) scale(1.01);
            width: ${calculate(height.tablet)}px;
          }

          @media (min-width: ${breakpoints.laptop}px) {
            width: ${calculate(height.laptop)}px;
          }

          @media (min-width: ${breakpoints.full}px) {
            width: ${calculate(height.full)}px;
          }

          @media (min-width: ${desktop}px) {
            width: ${calculate(height.desktop)}px;
          }

          @media (min-width: ${height.desktop * (16 / 9)}px) {
            padding-top: 56.25%;
            width: 100%;
          }
        `}
      ></div>
    )}
    <div
      css={css`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        margin: 0 auto;
        max-width: ${840 + 80}px;
        padding: 0 20px;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 100%;

        @media (min-width: 400px) {
          padding: 0 40px;
        }
      `}
    >
      <div
        css={css`
          margin-top: 25px;

          @media (min-width: 500px) {
            margin-top: 0;
          }

          @media (min-width: ${breakpoints.laptop}px) {
            margin-top: -50px;
          }

          @media (min-width: ${desktop}px) {
            margin-top: -100px;
          }

          h1,
          h2 {
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
          }
        `}
      >
        <h1
          css={css`
            color: white;
            line-height: 1.33;
            margin-bottom: 0.35em;

            @media (min-width: ${breakpoints.tablet}px) {
              font-size: 46px;
            }

            @media (min-width: ${breakpoints.laptop}px) {
              font-size: 60px;
            }
          `}
        >
          Ontdek de mooiste plekken op aarde.
        </h1>
        <h2
          css={css`
            color: white;
            font-family: ${fonts.body.join(',')};
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 1.75em;

            @media (min-width: ${breakpoints.tablet}px) {
              font-size: 30px;
            }
          `}
        >
          Voja travel experience van Fiji &amp; het Koninkrijk van Tonga
        </h2>
        <Anchor
          className={css`
            display: inline-block;
          `}
          target="#de-reis"
        >
          <Button arrow="down">Meer informatie</Button>
        </Anchor>
      </div>
    </div>

    <Wave position="hero" />
  </div>
))
