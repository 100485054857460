import { breakpoints, colors, fontSizes } from '../variables'

import Icon from './icon'
import React from 'react'
import { css } from '@emotion/react'

export default ({
  children,
  color,
  icon,
  small = false,
  strong = false,
  large = false,
}) => (
  <div
    css={css`
      align-items: center;
      break-inside: avoid-column;
      display: flex;
      margin-bottom: 15px;

      @media (min-width: ${breakpoints.tablet}px) {
        margin-bottom: 20px;
      }
    `}
  >
    <Icon
      className={`
        flex-shrink: 0;
        margin-right: 20px;
      `}
      color={color}
      name={icon}
      size={small ? 40 : 60}
      svgSize={large ? 1.5 : 1}
    />

    <span
      css={css`
        color: ${strong ? '#333' : colors.grey50};
        font-size: ${fontSizes.small};
        font-weight: ${strong ? 'bold' : 'normal'};
        line-height: 1.5;
      `}
    >
      {children}
    </span>
  </div>
)
