import Appointment from '../components/appointment'
import Experience from '../components/experience'
import Footer from '../components/footer'
import Header from '../components/header'
import Hero from '../components/hero'
import Information from '../components/information'
import Intro from '../components/intro'
import Itinerary from '../components/itinerary'
import Layout from '../components/layout'
import Prices from '../components/prices'
import React from 'react'
import Responsible from '../components/responsible'

export default class Index extends React.Component {
  state = {
    noScroll: false,
  }

  render() {
    return (
      <Layout noScroll={this.state.noScroll}>
        <Header onMenuChange={noScroll => this.setState({ noScroll })} />
        <Hero />
        <Intro />
        <Experience />
        <Itinerary />
        <Responsible />
        <Information />
        <Prices />
        <Appointment />
        <Footer />
      </Layout>
    )
  }
}
