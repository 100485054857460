import { breakpoints, colors, fonts } from '../variables'

import Anchor from './anchor'
import Button from './button'
import Line from './line'
import React from 'react'
import Subtitle from './subtitle'
import Usp from './usp'
import Wrapper from './wrapper'
import { css } from '@emotion/react'

const breakpoint = 1100

const Intro = () => (
  <div
    css={css`
      margin: 0 auto;
      max-width: 720px;
      text-align: center;
    `}
    id="prijzen"
  >
    <h2
      css={css`
        margin-bottom: 0.6em;
      `}
    >
      Prijzen
    </h2>
    <Line
      className={css`
        margin-left: auto;
        margin-right: auto;
      `}
    />
    <Subtitle
      className={css`
        margin-bottom: 3rem;

        @media (min-width: ${breakpoints.medium}px) {
          margin-bottom: 4rem;
        }

        @media (min-width: ${breakpoints.laptop}px) {
          margin-bottom: 5rem;
        }
      `}
    >
      Onderstaande prijzen zijn een indicatie. We maken altijd een voorstel op
      maat voor je
    </Subtitle>
  </div>
)

const Row = (props) => (
  <div
    css={css`
      border-radius: 5px;
      box-shadow: 0 0 15px ${colors.shadow};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.75rem 1.5rem 2.5rem;

      @media (min-width: ${breakpoints.small}px) {
        padding: 2rem 2.25rem 2.75rem;
      }

      @media (min-width: ${breakpoints.medium}px) {
        flex: none;
      }
    `}
  >
    <div>
      <h3
        css={css`
          margin-bottom: 0;
        `}
      >
        {props.title}
      </h3>
      <Subtitle
        className={css`
          margin-bottom: 0.8em;
        `}
        small={true}
      >
        {props.subtitle}
      </Subtitle>
      <Line
        small={true}
        className={css`
          @media (min-width: ${breakpoints.tablet}px) {
            margin-bottom: 2rem;
          }
        `}
      />
      <div
        css={css`
          margin-bottom: 1.75rem;
        `}
      >
        {props.children}
      </div>
    </div>
    <div>
      <h3
        css={css`
          font-family: ${fonts.heading.join(',')};
          margin-bottom: 1.25rem;
        `}
      >
        vanaf € {props.price}
      </h3>

      <Anchor target="#contact">
        <Button arrow={false}>Vraag reisvoorstel aan</Button>
      </Anchor>
    </div>
  </div>
)

export default React.memo(() => (
  <>
    <Wrapper>
      <Intro />
      <div
        css={css`
          margin-bottom: 60px;

          @media (min-width: ${breakpoints.laptop}px) {
            margin-bottom: 100px;
          }

          > div:not(:last-child) {
            margin-bottom: 30px;

            @media (min-width: ${breakpoint}px) {
              margin-bottom: 0;
            }
          }

          > div:nth-child(1),
          > div:nth-child(2) {
            @media (min-width: ${breakpoints.medium}px) {
              width: calc(50% - 15px);
            }

            @media (min-width: ${breakpoint}px) {
              width: 30%;
            }
          }

          > div:nth-child(3) {
            @media (min-width: ${breakpoints.medium}px) {
              width: 100%;
            }

            @media (min-width: ${breakpoint}px) {
              width: 30%;
            }
          }

          @media (min-width: ${breakpoints.medium}px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        `}
      >
        <Row title="Comfort" subtitle="" price="3.995">
          <Usp small={true} icon="airplane">
            22-daagse ultieme reis naar Fiji, Tonga &amp; Japan
          </Usp>
          <Usp small={true} icon="star">
            Inclusief vluchten, verblijf en accommodaties.
          </Usp>
          <Usp small={true} icon="star">
            Inclusief transfers en eilandhops
          </Usp>
          <Usp small={true} icon="star">
            Inclusief alle dagen ontbijt en 6 dagen alle maaltijden inbegrepen
          </Usp>
          <Usp small={true} icon="whale">
            Inclusief 1 dag zwemmen met walvissen onder begeleiding walvisgids.
          </Usp>
          <Usp small={true} icon="swimming">
            Inclusief 2 halve dagen zwemmen met manta roggen &amp;
            eilandexcursie
          </Usp>
          <Usp small large icon="voja-travel">
            Een once in a lifetime Voja travel experience.
          </Usp>
          <Usp small={true} icon="landing">
            Inclusief Stop Over in Tokyo
          </Usp>
          <Usp small={true} color="green" icon="leaf">
            Verantwoord op reis
          </Usp>
        </Row>

        <Row title="Comfort plus" subtitle="" price="4.995">
          <Usp small={true} icon="airplane">
            22-daagse ultieme reis naar Fiji, Tonga &amp; Japan
          </Usp>
          <Usp small={true} icon="star">
            Inclusief vluchten, verblijf en premium accommodaties.
          </Usp>
          <Usp small={true} icon="star">
            Inclusief transfers en eilandhops
          </Usp>
          <Usp small={true} icon="star">
            Inclusief alle dagen ontbijt en 6 dagen alle maaltijden inbegrepen
          </Usp>
          <Usp small={true} icon="whale">
            Inclusief 2 dagen zwemmen met walvissen onder begeleiding
            walvisgids.
          </Usp>
          <Usp small={true} icon="swimming">
            Inclusief 2 halve dagen zwemmen met manta roggen &amp;
            eilandexcursie
          </Usp>
          <Usp small large icon="voja-travel">
            Een once in a lifetime Voja travel experience.
          </Usp>
          <Usp small={true} icon="landing">
            Inclusief Stop Over in Tokyo
          </Usp>
          <Usp small={true} color="green" icon="leaf">
            Verantwoord op reis
          </Usp>
        </Row>

        <Row title="Luxury" subtitle="" price="15.995">
          <Usp small={true} icon="airplane">
            22-daagse ultieme reis naar Fiji, Tonga &amp; Japan
          </Usp>
          <Usp small={true} icon="star">
            Inclusief business class vluchten, verblijf en luxury accommodaties.
          </Usp>
          <Usp small={true} icon="star">
            Inclusief transfers en eilandhops
          </Usp>
          <Usp small={true} icon="star">
            Inclusief alle dagen ontbijt en 6 dagen alle maaltijden inbegrepen
          </Usp>
          <Usp small={true} icon="whale">
            Inclusief 2 dagen zwemmen met walvissen onder begeleiding
            walvisgids.
          </Usp>
          <Usp small={true} icon="swimming">
            Inclusief 2 halve dagen zwemmen met manta roggen &amp;
            eilandexcursie
          </Usp>
          <Usp small large icon="voja-travel">
            Een once in a lifetime Voja travel experience.
          </Usp>
          <Usp small={true} icon="landing">
            Inclusief Stop Over in Tokyo
          </Usp>
          <Usp small={true} color="green" icon="leaf">
            Verantwoord op reis
          </Usp>
        </Row>
      </div>
    </Wrapper>
  </>
))
