import { breakpoints, colors } from '../variables'

import Airplane from '../images/airplane.svg'
import AirplaneDown from '../images/airplane-down.svg'
import Boat from '../images/boat.svg'
import Calendar from '../images/calendar.svg'
import Camera from '../images/camera.svg'
import Check from '../images/check.svg'
import Cutlery from '../images/cutlery.svg'
import Fairtravel from '../images/fairtravel.svg'
import Landing from '../images/landing.svg'
import Leaf from '../images/leaf.svg'
import People from '../images/people.svg'
import React from 'react'
import Star from '../images/star.svg'
import Swimming from '../images/swimming.svg'
import Takeoff from '../images/takeoff.svg'
import Telephone from '../images/telephone.svg'
import Thumbsup from '../images/thumbsup.svg'
import VojaTravel from '../images/voja-travel.svg'
import Whale from '../images/whale.svg'

export default ({
  children,
  className,
  color = 'lightBlue',
  name,
  size = 60,
  svgSize,
}) => {
  const iconColors = {
    blue: {
      background: colors.blue75,
      fill: 'white',
    },
    green: {
      background: colors.green5,
      fill: colors.green75,
    },
    lightBlue: {
      background: colors.blue5,
      fill: colors.blue75,
    },
    white: {
      background: 'white',
      fill: colors.blue75,
    },
  }

  return (
    <div
      css={[
        `
        align-items: center;
        background-color: ${iconColors[color].background};
        border-radius: 100%;
        display: flex;
        height: 40px;
        justify-content: center;
        transform: rotate(${name === 'airplane' ? '-30deg' : '0deg'});
        width: 40px;

        @media(min-width: ${breakpoints.tablet}px) {
          height: ${size}px;
          width: ${size}px;
        }

        svg {
          fill: ${iconColors[color].fill};
          height: 16px;
          width: 16px;

          @media(min-width: ${breakpoints.tablet}px) {
            height: ${size * 0.4 * svgSize}px;
            width: ${size * 0.4 * svgSize}px;
          }
        }
      `,
        className,
      ]}
    >
      {name === 'airplane' && <Airplane />}
      {name === 'airplane-down' && <AirplaneDown />}
      {name === 'boat' && <Boat />}
      {name === 'calendar' && <Calendar />}
      {name === 'camera' && <Camera />}
      {name === 'check' && <Check />}
      {name === 'cutlery' && <Cutlery />}
      {name === 'fairtravel' && <Fairtravel />}
      {name === 'landing' && <Landing />}
      {name === 'leaf' && <Leaf />}
      {name === 'people' && <People />}
      {name === 'star' && <Star />}
      {name === 'swimming' && <Swimming />}
      {name === 'takeoff' && <Takeoff />}
      {name === 'telephone' && <Telephone />}
      {name === 'thumbsup' && <Thumbsup />}
      {name === 'voja-travel' && <VojaTravel />}
      {name === 'whale' && <Whale />}
    </div>
  )
}
